<template>
  <div v-if="Access('AreasView')" class="CookiesMainView">
    <div class="BigPlaceholder">
      <div class="BigContainer">
        <div class="BigHeader">
          <div class="D-Flex">
            <span class="F-Bold F1_75em BigHeaderText">{{LanguageSelector('Areas', 'المناطق')}}</span>
            <button v-if="Access('AreasAdd') && $route.name === 'Lines'" @click="CookiesAddLine()" style="margin-left: 0.4em; margin-right: 0.4em;" :style="'background: ' + $store.getters.Theme.UserColors.main" class="DialogHeaderButton F1_25em"><strong>+ {{LanguageSelector('New Line', 'خط جديد')}}</strong></button>
            <button v-if="Access('AreasAdd') && $route.name === 'MainAreas'" @click="CookiesAddArea()" style="margin-left: 0.4em; margin-right: 0.4em;" :style="'background: ' + $store.getters.Theme.UserColors.main" class="DialogHeaderButton F1_25em"><strong>+ {{LanguageSelector('New Main Area', 'منطقة رئيسية جديدة')}}</strong></button>
            <button v-if="Access('AreasAdd') && $route.name === 'SubAreas'"  @click="CookiesAddSubArea()" style="margin-left: 0.4em; margin-right: 0.4em;" :style="'background: ' + $store.getters.Theme.UserColors.main" class="DialogHeaderButton F1_25em" :class="CookiesDataLines.length !== 0 & CookiesDataAreas.length !== 0 ? '' : 'Disabled'"><strong>+ {{LanguageSelector('New Sub Area', 'منطقة فرعية جديدة')}}</strong></button>
          </div>
          <div class="D-Flex M-Auto CookiesSearchInputContainer" :style="$store.getters.Direction === 'ltr' ? 'margin-right: 0em;': 'margin-left: 0em;'">
            <input v-model="Search" class="CookiesSearchInput color2 F1_25em" :placeholder="LanguageSelector('Search...', 'بحث...')">
          </div>
        </div>
        <div class="BigBody P-Rel" style="flex-direction: column;">
          <CookiesTabs :Tabs="Tabs" style="margin-top: 1em;" ></CookiesTabs>
          <div class="W-100 M-Auto" style="overflow-y: scroll; border-top: solid 1px rgba(0, 0, 0, 0.034); margin-top: 0;">
            <router-view style="margin-bottom: 10em;" :Search="Search"></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  mounted () {
    this.ReadLines()
    this.ReadAreas()
  },
  data () {
    return {
      ImageSRC: 'https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png',
      CookiesDataItemsCategories: [],
      CookiesDataLines: [],
      CookiesDataAreas: [],
      Search: '',
      FilterStyle: false,
      Tabs: [
        { Name: this.LanguageSelector('Main Areas', 'المناطق الرئيسية'), Link: '/Areas/MainAreas', Value: 'MainAreas' },
        { Name: this.LanguageSelector('Sub Areas', 'المناطق الفرعية'), Link: '/Areas/SubAreas', Value: 'SubAreas' },
        { Name: this.LanguageSelector('Lines', 'الخطوط'), Link: '/Areas/Lines', Value: 'Lines' }
      ],
      sortElment: {
        name: ['LineID'],
        value: 1
      },
      RactiveHeader: {
        ID: ['ID'],
        Name: ['Name'],
        Fees: ['Fees'],
        DriverExtraFees: ['DriverExtraFees']
      },
      CookiesDataPages: [],
      Loading: false
    }
  },
  computed: {
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    CurrentDate () {
      return this.$store.state.CurrentDate
    }
  },
  methods: {
    ReadLines () {
      this.Loading = true
      this.Get('Area/getLine').then(response => {
        this.CookiesDataLines = response
        // this.ReadDrivers()
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Lines'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadAreas () {
      this.Loading = true
      this.Get('Area').then(response => {
        this.CookiesDataAreas = response
        // this.ReadDrivers()
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Lines'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    CookiesAddArea () {
      this.OpenDialog('NewArea', this.LanguageSelector('New Main Area', 'منطقة رئيسية جديدة'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    CookiesAddSubArea () {
      this.OpenDialog('NewSubArea', this.LanguageSelector('New Sub Area', 'منطقة فرعية جديدة'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    CookiesEditLines () {
      this.OpenDialog('EditLines', this.LanguageSelector('Edit Lines', 'الخطوط الموجودة'), this.CookiesDataLines) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    CookiesAddLine () {
      this.OpenDialog('NewLine', this.LanguageSelector('New Line', 'خط جديد'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    OpenArea (Area) {
      if (event.target.classList.length > 0) {
        if ((event.target.classList.includes('RowNoClick'))) {
          return
        }
      }
      this.OpenDialog('EditArea', 'Edit Area ' + Area.PlateNum, Area) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadLines()
      this.ReadAreas()
    }
  }
}
</script>
<style scoped>
.BigHeader{
  font-size: 0.9em;
}
.BigBody{
  font-size: 0.85rem;
}
@media (max-width: 500px) {
  .BigHeader>>>*{
    font-size: 1.5em !important;
  }
  .StatusBar>>>*{
    font-size: 1.3em !important;
  }
}
@media (max-width: 1400px) {
  .BigPlaceholder>>>*{
    font-size: 1.2vw;
  }
}
@media (max-width: 1200px) {
  .BigPlaceholder>>>*{
    font-size: 1vw;
  }
  .FilterPlaceholder{
    font-size: 15px;
  }
}
</style>
